<template>
  <div>
    <h4>Conversion rate</h4>
    <vue-apex-charts
      class="conversion-rate-card"
      :options="options"
      :series="computedSeries"
    />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

const SERIES_NAMES = {
  nb_conversions: 'Conversions',
  nb_visits_converted: 'Converted visitors',
  conversion_rate: 'Conversion rate',
  revenue: 'Revenue',
}

export default {
  components: {
    VueApexCharts,
  },
  props: {
    chart: {
      type: Object,
      required: true,
    },
    renderSerie: {
      type: String,
      required: true,
      default: 'conversion_rate',
    },
  },
  data() {
    return {}
  },
  computed: {
    hasData() {
      return this.chart.series.length !== 0
    },
    computedSeries() {
      const serie = this.chart.series.find(({ name }) => name === this.renderSerie)
      return serie !== null ? [serie] : []
    },
    chartId() {
      return `chart-experiment-${this.experimentId}`
    },
    options() {
      return {
        color: ['#28c76f'],
        legend: {
          show: false,
        },
        chart: {
          dropShadow: {
            blur: 4,
            enabled: true,
            left: 0,
            opacity: 0.1,
          },
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
          id: this.chartId,
        },
        colors: [
          '#28c76f',
        ],
        fill: {
          gradient: {
            gradientToColors: [
              '#55DD92',
            ],
            opacityFrom: 1,
            opacityTo: 1,
            shade: 'dark',
            shadeIntensity: 1,
            stops: [
              0,
              100,
              100,
              100,
            ],
            type: 'horizontal',
          },
          type: 'gradient',
        },
        grid: {
          padding: {
            left: 0,
            right: 0,
          },
          show: false,
        },
        markers: {
          hover: {
            size: 5,
          },
          size: 0,
        },
        stroke: {
          curve: 'smooth',
          width: 5,
        },
        xaxis: {
          categories: this.chart.categories,
          tickAmount: 4,
          labels: {
            formatter: value => `${value}`,
          },
        },
        yaxis: {
          min: 0,
          max(max) {
            return max <= 0.03 ? 0.03 : max
          },
          tickAmount: 5,
          show: true,
          labels: {
            formatter: value => `${Number(value * 100).toFixed(2)} %`,
          },
        },
        noData: {
          text: 'No data available',
          align: 'center',
          verticalAlign: 'middle',
        },
        tooltip: {
          custom: ({ dataPointIndex }) => {
            const values = this.chart.series.map(({ data, name }) => ({
              data: data[dataPointIndex],
              name: SERIES_NAMES[name],
            }))
            const rate = values.find(value => value.name === 'Conversion rate')
            if (rate) {
              rate.data = `${Number(rate.data * 100).toFixed(2)} %`
            }

            const tt = this.createHTMLElement('div', 'apexcharts-tooltip-text')
            const ttRowHeader = this.createHTMLElement('div', 'row apexcharts-tooltip-header')
            ttRowHeader.appendChild(this.createHTMLElement('span', 'col', this.chart.categories[dataPointIndex]))
            tt.appendChild(ttRowHeader)

            values.forEach(value => {
              const ttRow = this.createHTMLElement('div', 'row')
              const txtLabel = this.createHTMLElement('span', 'apexchart-tooltip-text-label col', value.name)

              const isCurrency = value.name === 'Revenue'
              const tooltipValue = this.numberFormat(value.data, isCurrency)
              const txtValue = this.createHTMLElement('span', 'apexcharts-tooltip-text-value col', tooltipValue)

              ttRow.appendChild(txtLabel)
              ttRow.appendChild(txtValue)
              tt.appendChild(ttRow)
            })
            return tt.outerHTML
          },
        },
      }
    },
  },
  methods: {
    xaxisFormater(value) {
      if (typeof value !== 'undefined') {
        return value
      }
      return ''
    },
    createHTMLElement(tag, classes, textContent) {
      const htmlElement = document.createElement(tag)
      htmlElement.setAttribute('class', classes)
      if (textContent) {
        htmlElement.textContent = textContent
      }
      return htmlElement
    },
    numberFormat(value, isCurrency = false) {
      if (typeof value !== 'number') {
        return value
      }
      const options = {}
      if (isCurrency) {
        options.currency = 'MXN'
        options.style = 'currency'
        options.currencyDisplay = 'narrowSymbol'
      }
      if (!value) {
        return Number(0).toLocaleString(this.getLang(), options)
      }
      return Number(value).toLocaleString(this.getLang(), options)
    },
    getLang() {
      if (navigator.languages !== undefined) { return navigator.languages[0] }
      return navigator.language
    },
  },
}
</script>

<style lang="scss" >
@import  '@/assets/scss/style';
.conversion-rate-card{

  .conversion-rate-chart{
    height: 100%;
    @media (max-width: 768px) {
      min-height: 20rem;
      position: relative;
      width: 100%;
    }
  }
  .apexcharts-xaxistooltip-bottom{
    opacity: 0 !important;
  }
  .apexcharts-tooltip {
    background: #FFF;
    color: rgb(0, 0, 0);
    border: 1px solid rgb(11, 146, 45);
    margin: .5rem !important;
    padding: .5rem !important;

    .apexcharts-tooltip-header{
      text-align: center;
      padding-bottom: .5rem;
      padding-top: .2rem;
      border-bottom: 1px solid rgb(11, 146, 45);
    }

    .apexcharts-tooltip-text-value{
      text-align: right;
    }
  }
  .conversion-percent{
    width: 10rem !important;
    flex-basis: 10rem;
  }
}
</style>
