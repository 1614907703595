<template>
  <div>
    <b-row>
      <b-col
        lg="12"
        xl="5"
      >
        <b-card no-body>
          <b-card-header>
            <b-card-title>
              {{ experiment.name }} |
              <small> {{ experiment.startDate | formatDate }} - {{ experiment.endDate | formatDate }}</small>
            </b-card-title>
            <b-card-sub-title class="p-2">
              {{ experiment.description }}
            </b-card-sub-title>
          </b-card-header>
          <b-card-body>

            <experiment-stats
              v-if="isLoaded"
              class="flex-grow-1"
              :values="metrics"
            />
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        lg="12"
        xl="7"
      >
        <b-card>
          <experiment-performance
            v-if="isLoaded"
            :chart="experiment.chart"
            render-serie="conversion_rate"
          />
        </b-card>
      </b-col>
      <b-col lg="12">
        <variants-analytic-table />
      </b-col>
    </b-row>
    <visit-log-sidebar />
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard, BCardHeader, BCardBody, BCardSubTitle, BCardTitle,
} from 'bootstrap-vue'
import variantsAnalyticTable from './variantsAnalyticTable.vue'
import ExperimentStats from '../list/ExperimentStats.vue'
import ExperimentPerformance from '../list/ExperimentPerformance.vue'
import VisitLogSidebar from '@/components/visitLog/VisitLogSidebar.vue'

import { mapActions, mapState } from 'vuex'

export default {
  components: {
    BRow,
    BCol,
    variantsAnalyticTable,
    BCard,
    ExperimentStats,
    ExperimentPerformance,
    BCardHeader,
    BCardBody,
    BCardSubTitle,
    BCardTitle,
    VisitLogSidebar,
  },
  filters: {
    formatDate(value) {
      if (value) {
        return value.split('T')[0]
      }
      return ''
    },
  },
  data() {
    return {
      isLoaded: false,
    }
  },
  computed: {
    ...mapState('experimentAnalytics', {
      experiment: state => state.experiment,
      efforts: state => state.efforts,
    }),
    metrics() {
      return {
        expectedConversions: this.experiment.expectedConversions,
        investment: this.experiment.investment,
        conversions: this.experiment.conversions,
        visits: this.efforts.reduce((acc, cur) => acc + cur.metrics.visits, 0),
      }
    },
  },
  created() {
    this.load()
  },
  methods: {
    ...mapActions('experimentAnalytics', {
      getEffortAnalytics: 'fetchEffortAnalytics',
      getExperimentMetrics: 'fetchExperimentMetrics',
    }),
    load() {
      // Load experiment if path includes an identifier
      const { id } = this.$route.params
      Promise.all([
        this.getExperimentMetrics(id),
        this.getEffortAnalytics(id),
      ]).then(() => {
        this.isLoaded = true
      })
    },
  },
}
</script>
