<template>
  <b-card-actions
    title="Experiment variants"
    no-actions
    no-body
  >
    <b-table
      striped
      borderless
      :fields="fields"
      :items="getItems"
      responsive
      class="mb-0 position-relative"

      primary-key="id"
      show-empty
      empty-text="No matching records found"
    >

      <!-- A virtual column -->
      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>

      <!-- A custom conversions formatted column -->
      <template #cell(conversions)="data">
        <b-progress
          :value="data.value.atribution"
          :max="totalConversions"
          height="7px"
          :variant="data.value.variant"
          striped
          @click="onClick(item.id)"
        />
        {{ data.value.conversions }}
      </template>

      <!-- A custom conversion rate formatted column -->
      <template #cell(conversionRate)="data">
        <b-badge
          pill
          :variant="getConversionRateStatus(data.value)"
        >
          {{ data.value | formatPercent }}
        </b-badge>
      </template>

      <!-- A custom revenue formatted column -->
      <template #cell(revenue)="data">
        {{ '$'+data.value }}
      </template>

      <!-- A custom cac formatted column -->
      <template #cell(cac)="data">
        {{ '$'+data.value }}
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-nowrap">

          <span
            v-clipboard:copy="data.item.url"
            v-b-tooltip.hover
            v-clipboard:success="onCopySuccess"
            title="Copy URL to clipboard"
            class="table-action-btn"
            role="button"
          >
            <feather-icon
              icon="LinkIcon"
              size="24"
              class="mx-1"
            />
          </span>
          <feather-icon
            v-b-tooltip.hover
            v-b-toggle.sidebar-visit-log
            icon="EyeIcon"
            size="24"
            class="mx-1 table-action-btn"
            title="Preview visits details"
            @click="onClick(data.item.id)"
          />
        </div>
      </template>
    </b-table>
  </b-card-actions>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { BTable, BProgress, BBadge, VBTooltip, VBToggle } from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { mapState, mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BTable,
    BProgress,
    BBadge,
    FeatherIcon,
    BCardActions,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  filters: {
    formatDate(value) {
      if (value) {
        return value.split('T')[0]
      }
      return ''
    },
    formatPercent(value) {
      const percent = 100 * value
      return `${percent.toFixed(2)}%`
    },
  },
  data() {
    return {
      fields: [
        // A virtual column that doesn't exist in items
        { key: 'index' },
        { key: 'name', label: 'Variant Name', sortable: true },
        { key: 'visits', label: 'Visits', sortable: true },
        { key: 'conversions', label: 'Conversions', sortable: true },
        { key: 'conversionRate', label: 'Conversions Rate', sortable: true },
        { key: 'revenue', label: 'Revenue', sortable: true },
        { key: 'cac', label: 'CAC', sortable: true },
        { key: 'actions', label: 'Actions' },
      ],
    }
  },
  computed: {
    ...mapState('experimentAnalytics', {
      efforts: state => state.efforts,
    }),
    totalConversions() {
      return this.efforts.reduce((acc, curr) => acc + curr.metrics.conversions, 0)
    },
    getItems() {
      return this.efforts.map(effort => ({
        id: effort.id,
        name: effort.name,
        url: effort.urlUtm,
        visits: effort.metrics.visits,
        conversions: { atribution: effort.metrics.conversions, conversions: effort.metrics.conversions },
        conversionRate: effort.metrics.conversionRate,
        revenue: effort.metrics.revenue,
        cac: effort.metrics.cac,
      }))
    },
  },
  methods: {
    ...mapActions('visits', {
      clearVisitLog: 'clearVisitLog',
      setDefaultFilters: 'setDefaultFilters',
    }),
    getConversionRateStatus(value) {
      if (value >= 0.05) {
        return 'success'
      }
      if (value >= 0.01) {
        return 'warning'
      }
      return 'danger'
    },
    onCopySuccess() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Experiment URL copied',
          icon: 'BellIcon',
          variant: 'success',
        },
      })
    },
    onClick(idEffort) {
      this.clearVisitLog()
      this.setDefaultFilters({
        idEffort,
        idGoal: -1,
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables/_variables.scss';
.card-content{
  padding: 0;
}
.table-action-btn {
  cursor: pointer;
}
.table-action-btn:hover {
  cursor: pointer;
  color: $primary;
}
</style>
