<template>
  <b-row
    class="d-flex h-100 align-items-center"
  >
    <b-col
      v-for="item in items"
      :key="item.icon"

      class="mb-2 mb-xl-2"
      :class="item.customClass"
    >
      <b-media no-body>
        <b-media-aside
          class="mr-2"
        >
          <b-avatar
            size="48"
            :variant="item.color"
          >
            <feather-icon
              size="24"
              :icon="item.icon"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body class="my-auto">
          <h4 class="font-weight-bolder mb-0">
            {{ item.title | formatNumber }}
          </h4>
          <b-card-text class="font-small-3 mb-0">
            {{ item.subtitle }}
          </b-card-text>
        </b-media-body>
      </b-media>
    </b-col>
  </b-row>
</template>
<script>
import {
  BCol,
  BRow,
  BAvatar,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardText,
} from 'bootstrap-vue'

export default {
  components: {
    BCol,
    BRow,
    BAvatar,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardText,
  },
  filters: {
    formatNumber(value) {
      const miles = value / 1000
      const milions = value / 1000000
      if (miles < 1000 && miles > 1) {
        return miles.toFixed(2).toString().concat('K')
      }
      if (milions > 1) {
        return milions.toFixed(2).toString().concat('M')
      }
      return value.toString().concat('')
    },
  },
  props: {
    values: {
      type: Object,
      default() {
        return {
          expectedConversions: 0,
          conversions: 0,
          investment: 0,
          visits: null,
        }
      },
    },
  },
  data() {
    return {
      statisticsItems: [
        {
          icon: 'AwardIcon',
          color: 'light-secondary',
          title: 0,
          subtitle: 'Expected conversions',
          customClass: 'mb-2 mb-xl-0',
          value: 'total',
        },
        {
          icon: 'PocketIcon',
          color: 'light-success',
          title: 0,
          subtitle: 'Conversions',
          customClass: 'mb-2 mb-xl-0',
          value: 'dropout',
        },
        {
          icon: 'TrendingUpIcon',
          color: 'light-info',
          title: 0,
          subtitle: 'Investment',
          customClass: 'mb-2 mb-xl-0',
          value: 'converted',
        },
      ],
    }
  },
  computed: {
    items() {
      const tmp = [...this.statisticsItems]
      tmp[0].title = this.values.expectedConversions
      tmp[1].title = this.values.conversions
      tmp[2].title = this.values.investment
      if (this.values.visits != null) {
        tmp.push({
          icon: 'EyeIcon',
          color: 'light-primary',
          title: this.values.visits,
          subtitle: 'Total visitors',
          customClass: 'mb-2 mb-xl-0',
          value: 'total',
        })
      }
      return tmp
    },
  },
}
</script>
